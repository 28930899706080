import "./RecentPost.scss"
import { news, newsVN } from "data/news"
import { useFormatter } from "helpers/i18n"

export default function RecentPost() {
  const { __, currentLocale } = useFormatter()

  const renderNews = () => {
    const _news = currentLocale === "vi" ? newsVN : news
    return _news.map((item, index) => (
      <div className="WrVuIYPtRQ" key={index}>
        <a className="hsUgKPqtCZ" href={item?.href}>
          {item?.title}
        </a>
        <div className="phrrVTvdup">
          <span className="kksDJxTlfk">{item?.timer}</span> /{" "}
          <span className="CENcaaMLOi"></span>
          <b>{item?.by}</b>
        </div>
      </div>
    ))
  }

  return (
    <div className="SPhJSjiKjF">
      <div className="JZxPMGRqig">
        {__({
          defaultMessage: "Press Coverage",
        })}
      </div>
      <div className="mBRglNsUaH">{renderNews()}</div>
    </div>
  )
}
